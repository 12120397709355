@import url('http://maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');
@import url('http://fonts.googleapis.com/css?family=Cookie');
@import url('http://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css'); /* Updated to Font Awesome v5 */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/* Footer base styling */
.footer-distributed {
    background-color: #2d2a30;
    width: 100%;
    text-align: left;
    font: bold 16px sans-serif;
    padding: 50px 50px 60px 50px;
    margin-top: 15px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
    display: inline-block;
    vertical-align: top;
}

@media (max-width: 800px) {
    .footer-distributed {
        margin-top: 50px;
    }
}

/* Footer left section */
.footer-distributed .footer-left {
    width: 30%;
}

.footer-distributed h3 {
    color: #ffffff;
    font: normal 36px 'Cookie', cursive;
    margin: 0;
}

.footer-distributed h3 span {
    color: #28a745; /* Changed to a vibrant green */
}

/* Footer links */
.footer-distributed .footer-links {
    color: #ffffff;
    margin: 20px 0 12px;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    text-decoration: none;
    color: inherit;
}

.footer-distributed .footer-links a:hover {
    color: #28a745; /* Hover effect on links */
}

.footer-distributed .footer-company-name {
    color: #8f9296;
    font-size: 14px;
    margin: 0;
}

.footer-distributed .footer-credit {
    margin-top: 10px;
    font-size: 14px;
}

.footer-distributed .footer-credit strong {
    font-size: 16px;
    color: #28a745;
}

.footer-distributed .footer-credit a {
    text-decoration: none;
    color: #28a745;
}

.footer-distributed .footer-credit a:hover {
    color: #ffffff; /* Hover effect for the portfolio link */
}

/* Footer Center */
.footer-distributed .footer-center {
    width: 35%;
}

.footer-distributed .footer-center i {
    background-color: #33383b;
    color: #ffffff;
    font-size: 25px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    margin: 10px 15px;
    vertical-align: middle;
}

.footer-distributed .footer-center p {
    display: inline-block;
    color: #ffffff;
    vertical-align: middle;
    margin: 0;
}

.footer-distributed .footer-center p a {
    color: #28a745; /* Updated to green */
    text-decoration: none;
}

.footer-distributed .footer-center p a:hover {
    color: #ffffff;
}

/* Footer Right */
.footer-distributed .footer-right {
    width: 30%;
}

.footer-distributed .footer-company-about {
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    margin: 0;
}

.footer-distributed .footer-company-about span {
    display: block;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Social Icons */
.footer-distributed .social-icons {
    margin-top: 25px;
}

.footer-distributed .social-icons a {
    display: inline-block;
    width: 35px;
    height: 35px;
    background-color: #33383b;
    border-radius: 50%;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

.footer-distributed .social-icons a:hover {
    background-color: #28a745; /* Green hover effect for social icons */
}

@media (max-width: 880px) {
    .footer-distributed .footer-left,
    .footer-distributed .footer-center,
    .footer-distributed .footer-right {
        display: block;
        width: 100%;
        margin-bottom: 40px;
        text-align: center;
    }
}
