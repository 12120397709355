.news-description a {
    color: #0073e6;
    text-decoration: underline;
    transition: color 0.2s ease;
}

.news-description a:hover {
    color: #005bb5; 
    text-decoration: underline;
}
