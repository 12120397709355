
.container_h {
    display: flex;
    flex-wrap: wrap; /* This allows cards to wrap to the next line if needed */
    width: 100%; /* This will make the container take up the full width of its parent */
}
.h11tag{
    font-size: 30px;
    padding: 25px 0px;
    text-align: center;
}
.p11tag{
    font-size: 20px;
    color: green;
    line-height: 1px;
    text-decoration: underline;
    text-align: center;
}
.card_h {
    position: relative;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    padding: 1.25rem;
    background: white;
    border-radius: 8px;
    flex: 1 0 auto;
    width: 100%;
    width: 1170px;
    margin: 0.1rem 1rem;
    box-sizing: border-box;
    transition: box-shadow 0.3s ease-in-out;
    display: flex;
    gap: 1px;
}
.child1_h{
    width: 17%;
}
.child2_h{
    width: 80%;
}
.img-section_h{
    width: 150px;
}

.card_h:hover {
    box-shadow: 0px 0px 0px 3px #edf1fd;
}

.title_h {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 1.2;
}

.content_h {
    font-weight: 600;
    color: green;
    font-size: 15px;
    line-height: 1.3;
    margin-bottom: 10px;
}

.additional-text_h {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 15px;
}


.button_h {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    color: white;
    background-color: #01411c;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.button_h:hover {
    background-color: #006400;
}
.description_h {
    display: -webkit-box;
    -webkit-line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em; 
    line-height: 1.5; 
}


.description_h a {
    color: #0073e6;
    text-decoration: underline;
    transition: color 0.2s ease;
}

.description_h a:hover {
    color: #005bb5;
    text-decoration: underline;
}


/* Responsive Styles */
@media screen and (max-width: 768px) {

    .card_h {
        width: 700px;
        padding-right: 2rem;
        overflow: hidden; /* Prevents overflow */
    }

    .button_h {
        width: 50%;
        padding: 12px;
    }
}

@media screen and (max-width: 480px) {
    .card_h {
        width: 325px;
        margin-left: -0.5rem;
        overflow: hidden; /* Prevents overflow */

    }

    .button_h {
        width: 50%;
        padding: 10px;
    }
    .title_h {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.2;
    }
    
    .content_h {
        font-size: 12px;
        line-height: 1.3;
        margin-bottom: 10px;
    }
    
    .additional-text_h {
        font-size: 10px;
        line-height: 1.2;
        margin-bottom: 15px;
    }
    .card_h .title_h, .card_h .content_h, .card_h .additional-text_h {
        /* Handle text overflow */
        overflow: hidden; /* Prevents overflow */
        text-overflow: ellipsis; /* Adds ellipsis (...) for overflowing text */
        word-wrap: break-word; /* Forces long words to wrap onto the next line */
        white-space: normal; /* Allows text to wrap onto multiple lines */
    }
}
