
.h1tag{
    color: white;
    text-align: center;
    font-size: 45px;
    padding: 20px 0px;
}
.BannerBox{
    padding: 200px 10px;
    height: 100vh;
}
#top{
    color: white;
    position: fixed;
    top: 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.singnin_btn{
    margin-left: -50px;
}

@media screen and (max-width: 768px) {
    .h1tag{
        font-size: 30px;
    }
}
@media screen and (max-width: 480px) {
    .h1tag{
        font-size: 22px;
        font-weight: 800;

    }
    .BannerBox{
        padding: 150px 10px;
        height: 100vh;
    }
}