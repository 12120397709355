* {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

a {
    text-decoration: none;
    color: inherit;
    font-family: 'Montserrat', sans-serif;
}

p {
    font-family: 'Poppins', sans-serif;
}